import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { AdjustableHeight } from '../helpers/slider-extensions';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import Img from 'gatsby-image';
import colorTheme from 'helpers/colorTheme';
import styles from './tabbedGalleryV2.module.scss';
import RichText from './richText';
import { ReactComponent as LeftCaretIcon } from 'images/icon-caret-left.svg';
import { ReactComponent as RightCaretIcon } from 'images/icon-caret-right.svg';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const TabbedGalleryV2 = ({ title, subtitle, tabs, sectionId }) => {
  const [containerRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const [currentSlideIndex, setCurrentSlideIndex] = useState(
    tabs.length > 0 ? 1 : 0
  );

  const splideRefs = useRef([]);

  const tabData = tabs.map((tab) => ({
    title: tab.title && tab.title,
    description: tab.description && tab.description,
    image: tab.images && tab.images[0],
  }));

  useEffect(() => {
    let intervalId;

    const startAutoplay = () => {
      intervalId = setInterval(() => {
        const splide = splideRefs.current[0];
        if (splide) {
          splide.go('>');
        }
      }, 5000);
    };

    const stopAutoplay = () => {
      clearInterval(intervalId);
    };

    startAutoplay();

    const sliderElement = document.querySelector('.splide');
    sliderElement.addEventListener('mouseenter', stopAutoplay);
    sliderElement.addEventListener('mouseleave', startAutoplay);
    sliderElement.addEventListener('focusin', stopAutoplay);
    sliderElement.addEventListener('focusout', startAutoplay);

    return () => {
      stopAutoplay();
      sliderElement.removeEventListener('mouseenter', stopAutoplay);
      sliderElement.removeEventListener('mouseleave', startAutoplay);
      sliderElement.removeEventListener('focusin', stopAutoplay);
      sliderElement.removeEventListener('focusout', startAutoplay);
    };
  }, []);

  return (
    <section
      //Color white is hardcoded because we want to use just backgroundColor white on TabbedGalleryV2.
      //But If we want to use another color, we can pass it as a prop and we must modify the opacity gradient of the .content on TabbedGalleryV2 to make it look good. Probably using JS.
      style={colorTheme('background-color', 'White')}
      className={classNames(styles.container, {
        [styles.revealed]: inView,
      })}
      id={sectionId}
      ref={containerRef}
    >
      <div className={classNames(styles.content)}>
        {title && (
          <h2
            className={classNames(styles.title, {
              [styles.withSubtitle]: subtitle,
            })}
          >
            {title}
          </h2>
        )}
        {subtitle && (
          <h3 className={styles.subtitle}>
            <RichText content={subtitle.json} />
          </h3>
        )}
        <div className={styles.text}>
          <h3>{tabData[currentSlideIndex].title}</h3>
          <p>{tabData[currentSlideIndex].description}</p>
        </div>

        <Splide
          options={{
            rewind: true,
            perPage: 1,
            start: currentSlideIndex,
            focus: 'center',
            trimSpace: false,
            speed: 200,
            arrows: false,
            gap: '32px',
            width: '1600px',
            breakpoints: {
              769: {
                padding: '25%',
              },
              768: {
                padding: '0',
              },
            },
          }}
          extensions={{ AdjustableHeight }}
          aria-label="Interactive gallery of images and text"
          ref={(el) => (splideRefs.current[0] = el)}
          onMove={(splide) =>
            setCurrentSlideIndex(splide.index % tabData.length)
          }
          hasTrack={false}
        >
          <div className="splide__arrows">
            <button
              className="splide__arrow splide__arrow--prev"
              onClick={() => splideRefs.current[0].go('<')}
            >
              <LeftCaretIcon />
            </button>
            <button
              className="splide__arrow splide__arrow--next"
              onClick={() => splideRefs.current[0].go('>')}
            >
              <RightCaretIcon />
            </button>
          </div>
          <SplideTrack>
            {tabData.map((tab, index) => (
              <SplideSlide key={index} className={styles.tab}>
                <div
                  className={styles.image}
                  onClick={() => {
                    splideRefs.current[0].go(index);
                  }}
                >
                  {tab?.image?.fluid ? (
                    <Img
                      alt={tab.description || ''}
                      key={tab.image}
                      className={styles.panel__image}
                      fluid={tab.image.fluid}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className={styles.panel__image}
                      src={tab?.image?.file?.url}
                      loading="lazy"
                      alt={tab.description || ''}
                    />
                  )}
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide>
      </div>
    </section>
  );
};

export default TabbedGalleryV2;
